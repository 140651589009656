import './App.css';
import logo from './logo_placeholder.svg';
function App() {
  return (
    <>
  <div className="App" style={{padding:"2rem"}}>
    <header className="App-header">
      <img src={logo} alt="Logo" />
    </header>
    <div className="main">
      <main className="mx-auto min-h-[calc(100vh_-_var(--fixed-top)_-_var(--cookie-hint-height))] w-full min-w-[200px] max-w-[980px] whitespace-break-spaces p-[15px] pt-[--fixed-top] homePad:px-[45px] homePad:pb-[36px]">
        <div className="terms-content">
          <h2 style={{ marginLeft: 0 }}>
            <span style={{ color: "#3b3838" }}>Privacy Notice</span>
          </h2>
          <p>
            <span style={{ color: "#3b3838" }}>
              Welcome to Playsee! Playsee is a public open network platform{" "}
            </span>
            <span style={{ backgroundColor: "transparent", color: "#3b3838" }}>
              that allows you to create and post content, including videos,
              comments, information, text, graphics, photos, music, sounds,
              voice, likes, comments, shares, collections, saves, hashtags and
              other material that you generate (“<strong>User Content</strong>
              ”).&nbsp;
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#3b3838" }}>
              This Privacy Notice explains how Playsee (“
              <strong>Playsee</strong>,” “<strong>we</strong>,” or “
              <strong>us</strong>”) collects, uses, and shares personal
              information when you explore, sign up for or access our “
              <strong>Services</strong>”, which includes the Playsee mobile
              application and any related web services, software applications,
              and technologies where this Privacy Notice is posted.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#3b3838" }}>
              <strong>
                By using our Service, you understand and agree that we are
                providing a platform for you to post your content and to share
                your content publicly. Unless you change your account settings
                to private, your content will be public to all users.
              </strong>
              This means that potentially anyone in the world may search for,
              see, use, or share any of the content that you make publicly
              available through the Service, consistent with the terms and
              conditions of this Privacy Notice, our&nbsp;
            </span>
            <a href="https://playsee.co/user-terms">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                User Terms of Service
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              ,&nbsp;
            </span>
            <a href="https://playsee.co/biz-terms">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                Additional Terms of Service (Business)
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#4c8eb4" }}>
              , Subscription Terms of Service
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              ,
            </span>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              {" "}
              and any other applicable terms posted on the Service ("
              <strong>Terms</strong>”).
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              If you have questions about this Notice, how we collect or process
              your personal information, or anything else related to our privacy
              practices, please contact us at any time via method specified in
              the “Contact Us” section below.
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              1. Who controls your information?
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Any personal information provided to or gathered by Playsee is
              controlled by:
            </span>
            <br />
            <br />
            Playsee Pte. Ltd.&nbsp;
            <br />
            12 MARINA VIEW, #12-05, ASIA SQUARE TOWER 2, SINGAPORE 018961
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              2. What types of information do we collect?
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              We collect certain personal information when you use our Services.
              Personal information, used interchangeably with “personal data”,
              is typically data that identifies an individual or relates to an
              identifiable individual. Playsee collects the following categories
              of personal information:
            </span>
          </p>
          <ul>
            <li>
              <span
                style={{
                  backgroundColor: "transparent",
                  color: "hsl(0,0%,30%)"
                }}
              >
                <strong>Personal Information You Provide</strong>. We collect
                personal information from you when you voluntarily provide it to
                us when using the Service.
              </span>
              <ul>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Account Registration.&nbsp;</strong>When you
                    register an account with us, we may collect your username,
                    password, nickname, bio, email address, phone number,
                    profile picture and gender. As you must be 13 years old and
                    above to use Playsee Services, and for us to comply with
                    applicable laws, such as COPPA, we must obtain your date of
                    birth. However, your email address, phone number and birth
                    date will remain private unless you provide Playsee with
                    additional consent to make the personal information public.
                    For a Business to create a business account, a username,
                    business name, password and email address will be required.
                    You can also voluntarily provide personal information about
                    business, for example a bio, phone number, contact email
                    address and website URL.&nbsp;
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Upgrade to Official Account.</strong> When you
                    upgrade to an Official Account in order to qualify for
                    certain subscription services, we may collect your username,
                    password, email address, and birthday. When you upgrade to
                    an Official Account and/or subscribe to additional services,
                    we may collect certain transaction payment information. We
                    use third parties, such as Stripe, Google Play Store or
                    Apple App Store, to process payments for services you buy
                    when using our Service. For example, when you purchase
                    advertising services, we receive limited personal
                    information related to the purchase you made (namely, a user
                    identification number, or a purchase receipt from
                    third-party payment services ). These third parties act as
                    independent controller, and any personal information you
                    provide (namely, your legal name, credit or debit card
                    number, card expiration date, CVV code, and billing address)
                    to them is not governed by this Privacy Notice.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Photos and Videos</strong>. For you to upload
                    content from your camera roll, such as videos and photos,
                    you must grant us access to your camera or photos/albums.
                    When you take these steps, Playsee may collect and process
                    data relating to the photos and videos, such as album names,
                    the videos and photos stored in each album, the time and
                    location the content was created, and the amount of content
                    stored in each album.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Location Information</strong>. We may collect
                    information about where you are located, both generally and
                    at the time you access the Service. For example, if you want
                    to upload your content, you must turn on the Location
                    setting on your device. Playsee will then be able to collect
                    your location data. If you do not share your precise
                    location with us, we may still use your IP address to infer
                    your location for the purpose of providing the Service. We
                    will not store every location you have been to, but we will
                    store the last known location where you used the Service.
                    Additionally, we will store every location where you checked
                    in for your User Content. Note that your check-in location
                    is not necessarily the same as your current location.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Newsletter Sign-ups</strong>. If you wish to receive
                    promotional emails from us with news &amp; exclusive
                    contents, you will need to provide us your email address.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Content You Have Published</strong>. We collect
                    personal information from you that you voluntarily upload to
                    the Service for public or private display (“
                    <strong>Published Information</strong>”), such as User
                    Content, which may include videos, text, hashtags, photos,
                    nickname, and bio. Once you submit Published Information,
                    neither you nor Playsee can control how it may be copied,
                    shared, or used by third parties, even if you and/or Playsee
                    later delete such Published Information. Therefore you
                    should carefully consider what personal information you
                    choose to include within your Published Information.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Messages</strong>. We provide a messaging feature
                    for you to have non-public conversations on Playsee. When
                    you communicate with others by sending or receiving
                    messages, we will store your communications and personal
                    information related to such communications. We only share
                    the content of your messages with the message recipient.
                    When you use the messages feature to communicate with other
                    users, the message recipients will have their own copy of
                    your communications on Playsee, even after you delete your
                    copy of those messages from your account.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Finding Friends</strong>. When you use our Find
                    Friend service to connect with your friends, you will need
                    to enter your phone number, and agree to provide Playsee
                    with access to your phone book or contact list so that we
                    can assist you and your friends find each other. We sync you
                    with your friends by cross-referencing the phone numbers in
                    your address book with the phone numbers of other Playsee
                    users who have provided us with their phone numbers. We
                    require the consent of both users before we will
                    cross-reference phone numbers. You can also connect your
                    Facebook account to the Service to help you find friends who
                    are also on Playsee.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Customer Support</strong>. When you engage with our
                    support team and customer support providers, you may provide
                    us with your email address, records, correspondence and
                    other information so we can address your concerns.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "hsl(0,0%,30%)"
                    }}
                  >
                    <strong>Other Personal information</strong>. From time to
                    time, we may collect other personal information from you,
                    such as when you make a claim for a prize pursuant to a
                    contest or other promotions we may run, when you request
                    assistance through our customer service department, or when
                    you voluntarily subscribe to promotions, reminders, or
                    newsletters.
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <span
                style={{ backgroundColor: "transparent", color: "#314146" }}
              >
                <strong>Personal Information We Automatically Collect</strong>.
              </span>
              <ul>
                <li>
                  <span
                    style={{ backgroundColor: "transparent", color: "#314146" }}
                  >
                    <strong>Device/Internet Connection Information</strong>.
                    When you use the Service, we automatically gather personal
                    information made available by your web browser, internet
                    service provider, and device, depending on your settings for
                    each. For example, we may collect information about your
                    hardware and software (namely, your device model, operating
                    system version, device memory, advertising identifiers,
                    unique application identifiers, apps installed, unique
                    device identifiers, browser type, language, battery level,
                    and time zone).
                  </span>
                </li>
                <li>
                  <span
                    style={{ backgroundColor: "transparent", color: "#314146" }}
                  >
                    <strong>Internet Activity Information</strong>. When you
                    interact with the Service, we may also collect information
                    about your server logs, access dates and times, operating
                    system, browser type, IP address, languages, clicks, views,
                    collection, engagements, search terms, Service features or
                    pages viewed, time spent on pages viewed or on each feature,
                    browsing history, search history, and information regarding
                    your interaction with a particular website, application, or
                    advertisement, including the Service. For information about
                    the first- and third-party cookies and tracking technology
                    we use, please see Section 3 below.&nbsp; We may also
                    collect such information from emails we send to users,
                    allowing us to understand the open rate of emails and
                    click-through rates of links.&nbsp;
                  </span>
                </li>
              </ul>
            </li>
            <li>
              <span
                style={{ backgroundColor: "transparent", color: "#314146" }}
              >
                <strong>
                  Personal Information We May Collect From Third Parties
                </strong>
              </span>
              <ul>
                <li>
                  <span
                    style={{ backgroundColor: "transparent", color: "#314146" }}
                  >
                    <strong>Identifiers and Social Network Information</strong>.
                    We may collect unique identifiers, device IDs and other
                    identifiers from the Facebook, Google or Apple accounts that
                    you choose to integrate with Playsee, including when you
                    sign-up or log-in through them. If you choose to integrate
                    your Playsee account with a supported third party single
                    sign-on service, we will collect the authentication token
                    provided by such third party social network. If you choose
                    to sign up with Playsee by using a social network or connect
                    your existing Playsee account to other social networks, you
                    will be prompted to provide consent via a popup notice
                    informing you that by selecting “Continue,” you will allow
                    the platform to share information about you. Personal
                    information can be shared with social networks when users
                    share profiles, videos, and locations to other Third-Party
                    Platforms, such as Facebook, Messenger, Message, Twitter,
                    WhatsApp, Line and Email. This information can include your
                    username, videos, followers, location, and a URL to shared
                    content. Personal information collected by these third-party
                    services is subject to their terms and policies.
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              <strong>Personal Information We May Draw From Inference</strong>.
              We may also create new information about you by drawing inferences
              from the personal information identified in the categories listed
              above to reflect your preferences or personal interests.&nbsp;
              Under certain laws, this inference information is considered
              personal information.&nbsp;
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              3. Cookies and Other Tracking Technologies
            </span>
          </h3>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              We may automatically collect information from you when you use the
              Services using cookies and other similar technologies, such as web
              beacons, pixels, tags (“Tracking Technologies”)
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              .&nbsp;
            </span>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              We and third-party businesses on our behalf, may use this
              information to understand your behavior and interests on Playsee,
              such as the User Content you viewed or engaged with, frequency and
              duration of the engagement, or other User or Business profiles you
              interacted with; to keep you logged into Services; to remember
              your search terms; and to advertise products or services to
              you.&nbsp; “Cookies” are files that are placed on your computer or
              other device by Services you visit. The primary purpose of a
              cookie is to identify you as a unique user of the Service.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              Please refer to our&nbsp;Cookie Policy to understand the types of
              cookies we use, why we use them, and to learn about how to control
              cookie settings at your device level.&nbsp;
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              4. Why We Collect Your Personal Information
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              By collecting your personal information, we can provide and
              improve our Services, monitor ads and metrics, personalize your
              user experience, connect you to your friends and other users,
              research and develop new products and features, fix technology
              problems, send you updates or communicate with you, and protect
              our community and User Content. The chart below identifies the
              reasons we collect and process your personal information, and the
              legal bases for such processing.&nbsp;
            </span>
          </p>
          <figure className="table">
            <table>
              <thead>
                <tr>
                  <th>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>Processing Purposes</strong>
                    </span>
                  </th>
                  <th>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>Legal Bases</strong>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>
                        To Provide the Service and Fulfill Your Purchase
                      </strong>
                      . We use the personal information we gather through the
                      Services to provide you with our Services that you
                      request, such as creating and managing user accounts,
                      posting and pushing User Content, processing your purchase
                      and/or prescriptions.&nbsp;
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our contract with you or to take steps at your
                      request prior to entering a contract.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>To Ensure Security of the Services</strong>.
                      Playsee may use the personal information we collect from
                      you and other users to detect security incidents, protect
                      you and your personal information against malicious,
                      deceptive, fraudulent, or illegal activity, and to
                      identify and prosecute those responsible for that
                      activity.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legal obligations.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>To Communicate with You</strong>. We use your
                      information to personalize communication with you. We may
                      send you administrative or account-related information to
                      keep you updated about your account and the Services. Such
                      communications may include information about privacy
                      policy and terms updates, security updates, tips or other
                      relevant transaction-related information. Service-related
                      communications are not promotional in nature. You are not
                      able to unsubscribe from such communications, otherwise
                      you may miss important developments relating to your
                      account or the Services.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our contract with you or to take steps at your
                      request prior to entering a contract.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>
                        To Provide Customer Support or Respond to You.&nbsp;
                      </strong>
                      We collect any information that you provide to us when you
                      contact us. Without your personal information, we cannot
                      respond to you or ensure your continued use and
                      satisfaction of the Services.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our contract with you or to take steps at your
                      request prior to entering a contract.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>
                        To Provide Marketing Communications.&nbsp;
                      </strong>
                      If you have voluntarily provided your email address or
                      phone number or when subscribing to our marketing
                      communications, we may send you communication about our
                      services and promotions that we believe may be of interest
                      to you. When you no longer wish to receive these marketing
                      messages from us, you can opt out at any time by following
                      the instructions contained in each communication you
                      receive from us or contacting us via the methods specified
                      in the “Contact Us” section below.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legitimate interest to operate our business
                      and not overridden by your data protection interests or
                      fundamental rights and freedom.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>To Improve Our Service</strong>. Playsee uses the
                      personal information we collect from you and other users
                      to improve our product offerings and services. For
                      example, we may consider analytics, demographics, and user
                      feedback in determining whether there is sufficient demand
                      for a new service.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legitimate interest to operate our business
                      and not overridden by your data protection interests or
                      fundamental rights and freedom.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>To Personalize Your User Experience</strong>. We
                      use personal information gathered from Cookies to
                      authenticate your logins, determine whether you are logged
                      in or not, to allow you to set personal preferences when
                      using the Service, and to make it so you don’t have to
                      reenter the same personal information when you visit the
                      Service several times within a short timeframe.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legitimate interest to operate our business
                      and not overridden by your data protection interests or
                      fundamental rights and freedom.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>To Suggest Users</strong>. We may suggest other
                      users, whom you have similar interests with, for you to
                      follow. For example, we may make suggestions based on who
                      your other friends follow.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legitimate interest to operate our business
                      and not overridden by your data protection interests or
                      fundamental rights and freedom.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>To Advertise to You</strong>. We may use
                        personal information gathered through Cookies and web
                        beacons, including those placed by Third Parties, to
                        deliver ads to you via the Service, and after you’ve
                        left the Service, including advertising specific to your
                        personal interests. The purpose of this practice, which
                        is sometimes called “Personalized Ads,” “retargeting,”
                        or “remarketing” is to deliver content and advertising
                        that is of interest to you, and to avoid displaying
                        irrelevant advertising to you.{" "}
                      </span>
                      <span style={{ color: "hsl(0,0%,30%)" }}>
                        You can better learn about such Third-Party data
                        collection and advertising practices, including how to
                        revoke your consent to Personalized Ads, at the
                        following websites:
                      </span>
                    </p>
                    <ul>
                      <li>
                        <a href="http://www.youronlinechoices.com/uk/your-ad-choices">
                          <span style={{ color: "#4c8eb4" }}>
                            www.youronlinechoices.com/uk/your-ad-choices
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="http://www.networkadvertising.org/choices">
                          <span style={{ color: "#4c8eb4" }}>
                            www.networkadvertising.org/choices
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="http://www.aboutads.info/choices">
                          <span style={{ color: "#4c8eb4" }}>
                            www.aboutads.info/choices
                          </span>
                        </a>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on your consent.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>To Infer Your Interests</strong>. Playsee creates
                      new personal information about you by drawing inferences
                      from the personal information identified in Section 2 to
                      reflect your preferences or interests. We may use such
                      personal information to recommend more relevant content or
                      advertisements to you.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legitimate interest to operate our business
                      and not overridden by your data protection interests or
                      fundamental rights and freedom.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>To Advertise to Others</strong>. We may share your
                      reviews, comments, and other content uploaded by you to
                      advertise and market our Service, including by reposting
                      your reviews on the App, in our advertising, or through
                      our social media accounts.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on your consent.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>
                        To Enforce Compliance with Our Terms, Agreements and
                        Policies.&nbsp;
                      </strong>
                      When you access or use our Services, you are bound to our
                      Terms. We may process your personal information when
                      actively monitoring, investigating, preventing and
                      mitigating any alleged or actual prohibited, illicit or
                      illegal activities on our Services. We may also process
                      your personal information to investigate, prevent or
                      mitigate violations of our internal terms, agreements or
                      policies; and enforce our agreements with third parties.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legal obligations.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>
                        Maintain Legal and Regulatory Compliance.&nbsp;
                      </strong>
                      Our Services are subject to certain laws and regulations
                      which may require us to process your personal information.
                      For example, we process your personal information to pay
                      our taxes, to fulfill our business obligations, or as
                      necessary to manage risk as required under applicable law,
                      or to respond to requests by judicial process or
                      governmental agencies.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legal obligations<i>.</i>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>
                        To Facilitate Corporate Acquisitions, Mergers and
                        Transactions.&nbsp;
                      </strong>
                      &nbsp;We may process any information regarding your
                      account and your use of our Services as is necessary in
                      the context of corporate acquisitions, mergers or other
                      corporate transactions.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legitimate interest to operate our business
                      and not overridden by your data protection interests or
                      fundamental rights and freedom.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>With Your Consent.&nbsp;</strong>&nbsp;For any
                      other purpose disclosed to you prior to you providing us
                      your personal information or which is reasonably necessary
                      to provide the services or other related services
                      requested, with your permission or upon your direction.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on your consent.&nbsp;&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              5. Why We Share Personal Information
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Playsee may share your personal information as outlined
              below.&nbsp; We do not sell any of your personal
              information.&nbsp; For individuals located in European Countries,
              please refer to the Legal Bases column in the chart below to
              understand the legal bases under GDPR for processing your personal
              information.
            </span>
          </p>
          <figure className="table">
            <table>
              <thead>
                <tr>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>Other Parties</strong>
                      </span>
                    </p>
                  </th>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>Legal Bases</strong>
                      </span>
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>Other Users and the Public</strong>. The public at
                      large and your fellow users, including businesses and
                      advertisers, will have access to the personal information
                      and User Content you have published or uploaded onto our
                      Service. For example, your username, nickname, Avatar, and
                      bio are shared in the community. If you share User Content
                      (namely, comments, videos, likes, collections, locations,
                      publicly), it will be available to everyone. If you do not
                      wish other users to see your uploaded video on Playsee,
                      you may set your video to private when uploading the
                      video. If you only wish your followers to see the videos
                      you uploaded, you may set your account to private in the
                      privacy settings. You are also able to select who can see
                      your comments in the privacy settings. You should also
                      keep in mind that even if you intend to share User Content
                      only with select users (and accordingly apply the
                      appropriate privacy settings), those users, and other
                      persons (as the case may be), may use other methods and
                      devices to capture and share your User Content or personal
                      information without your permission. You are responsible
                      for your User Content and should think carefully about
                      what you make public, especially if it is sensitive
                      personal information. Playsee cannot control the
                      transmission of Public Content, even if you try to delete
                      it. Additionally, we may share your reviews, comments, and
                      public User Content to advertise and market our Service,
                      including by reposting in our advertising or through our
                      social media accounts.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our contract with you or to take steps at your
                      request prior to entering a contract.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>Our Affiliated Entities</strong>. Affiliated
                      entities are different from third parties in that they are
                      businesses that are directly related to us and have common
                      ownership with us. We transfer your personal information
                      to our affiliated entities, who treat your personal
                      information in accordance with this Privacy Notice. Should
                      we be subject to an acquisition, merger, sale or other
                      change of control or business entity status, we reserve
                      the right to transfer or assign your personal information
                      as part of that acquisition, merger or sale.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our contract with you or to take steps at your
                      request prior to entering a contract.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>Service Providers</strong>. We use service
                      providers to provide services and products on our behalf
                      and will share your personal information with them as
                      necessary. Examples include payment service providers and
                      customer support.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our contract with you or to take steps at your
                      request prior to entering a contract.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>Third-Party Platforms</strong>. Your registered
                      email address is shared with Amazon Web Services to enable
                      push notifications and email services to reset your
                      password. User Content is shared and stored on the Google
                      Cloud Platform. We also share your personal information
                      with analytics and search engine providers, namely, Google
                      Analytics, Firebase, Facebook Analytics, App Store
                      Connect, and Google Play Console, to assist us in the
                      optimization and improvement of the Service.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our contract with you or to take steps at your
                      request prior to entering a contract.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>
                        Government Officials and Private Parties Pursuant to
                        Valid Legal Process
                      </strong>
                      . We may disclose your personal information in response to
                      subpoenas, court orders, and other legal processes. Under
                      limited circumstances, we may disclose personal
                      information when we believe it is reasonably necessary in
                      order to prevent illegal, fraudulent, or injurious actions
                      that may cause harm to individuals, or the public at
                      large. We may disclosure your personal information if we
                      believe that you have abused the Site by using it to
                      attack other systems or to gain unauthorized access to any
                      other system, to engage in spamming or otherwise to
                      violate applicable laws.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legal obligations.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>Advertising Partners</strong>. We may let
                      Advertisers know the performance of their ads on Playsee
                      with aggregated and anonymous information, such as the
                      number of people who view the ads or who followed their
                      profiles.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our contract with you or to take steps at your
                      request prior to entering a contract.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>
                        During Business Transfers; Bankruptcy.&nbsp;
                      </strong>
                      In the event of a merger, acquisition, bankruptcy or other
                      sale of all or a portion of our assets, any personal
                      information owned or controlled by us may be one of the
                      assets transferred to third parties. We reserve the right,
                      as part of this type of transaction, to transfer or assign
                      personal information and other information we have
                      collected from users of the Services to buyers, service
                      providers, advisors, potential transactional partners or
                      other third parties in connection with the advisors,
                      potential transactional partners or other third parties of
                      a corporate transaction. Other than to the extent ordered
                      by a bankruptcy or other court, the use and disclosure of
                      all transferred user information will be subject to this
                      Privacy Notice. However, any information you submit or
                      that is collected after this type of transfer may be
                      subject to a new privacy policy adopted by the successor
                      entity.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on our legitimate interest to operate our business
                      and not overridden by your data protection interests or
                      fundamental rights and freedom.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      <strong>With Your Consent.&nbsp;</strong>We may share your
                      personal information with other companies if you give us
                      permission or direct us to share your information.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Based on your consent.&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>6. Third-Party Links</span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Please note that this Privacy Notice only applies to the Service,
              and not to website, applications, operating systems, or other
              platforms of third parties (collectively, “
              <strong>Third-Party Platforms</strong>”), even if such Third-Party
              Platforms are linked to or accessible from the Service. Each
              Third-Party Platform will have its own data collection and usage
              practices, and may use their own cookies, web beacons, and other
              technology to collect information from you. Playsee is not
              responsible for the content or practices of any Third-Party
              Platforms and, if you choose to visit or use them, you do so at
              your own risk.
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              7. How We Protect Personal Information
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Playsee takes steps to protect your personal information and data
              from any unauthorized processing activities. We employ technical,
              administrative, and physical security measures that are designed
              to assist us in protecting against the loss, misuse, and
              alteration of personal information under our control. However, no
              system can be completely secure.&nbsp;We cannot guarantee the
              security of our databases or the databases of the third parties
              with which we may share such information, nor can we guarantee
              that the information you supply will not be intercepted while
              being transmitted over the Internet. Please note that you play a
              valuable part in security. Never share your password with any
              third parties and please notify us as soon as possible if you
              believe your account security has been compromised by contacting
              us via methods identified in the “Contact Us” section.
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>8. Retention</span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Playsee will retain your personal information for the length of
              time necessary to fulfill the purposes of processing outlined in
              this Privacy Notice, unless a longer retention period is required
              or permitted by law or due to a valid court order.&nbsp; If you
              wish to delete your account or request that we no longer use your
              information, please note that we may still retain and use your
              information as necessary to comply with our legal obligations,
              resolve disputes, and enforce our agreements.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              In some circumstances, we may anonymize your personal information
              (so that it can no longer be used to identify you) for analytics
              or research purposes, in which case we may use this such
              anonymized information indefinitely without further notice to you.
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              9. International Transfers
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Playsee operates a neighborhood community platform on a global
              scale. We use a third-party hosting partner to run Playsee's
              infrastructure and may transfer your personal information to our
              affiliates globally to assist in providing the Services. Because
              these hosting partners and their facilities are located worldwide,
              we may be required to transfer and store your personal information
              in countries where the data protection laws may provide a
              different level of protection compared to the laws in your
              jurisdiction. We will take necessary measures to ensure that these
              transfers are adequately protected as required by applicable data
              protection laws.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              For individuals located in the European Economic Area (“EEA”), the
              United Kingdom and Switzerland (collectively, “European
              Countries”), we rely primarily on the European Commission’s
              Standard Contractual Clauses to facilitate the international and
              onward transfer of personal information to the extent the
              businesses receiving the such person information are located in a
              country that the European Countries consider to not provide an
              adequate level of data protection. We may also rely on an adequacy
              decision of the relevant regulatory body confirming an adequate
              level of data protection in the jurisdiction of the party
              receiving the information, or derogations in specific
              situations.&nbsp;
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              10. Your Privacy Rights
            </span>
          </h3>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              Depending on applicable law where you reside or are located at the
              time your personal information is collected, you may be able to
              assert certain rights identified below with respect to your
              personal information. These requests can be made by contacting us
              via email at&nbsp;
            </span>
            <a href="mailto:privacy@playsee.co">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                privacy@playsee.co
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              . Please include your name, username, and registered email with
              your message. To protect your privacy and security, we may take
              reasonable steps to help verify your identity before granting
              access, making corrections, providing you with personal
              information, or deleting personal information.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              Your rights in relation to your personal information are not
              absolute. Depending upon the applicable law, access to your rights
              may be denied: (a) when denial of access is required or authorized
              by law; (b) when granting access would have a negative impact on
              another’s privacy; (c) to protect our rights and properties; (d)
              where the request is frivolous or vexatious, or (e) for other
              reasons.
            </span>
          </p>
          <ul>
            <li>
              <span
                style={{ backgroundColor: "transparent", color: "#314146" }}
              >
                <strong>Access and Correction.&nbsp;</strong>Under certain
                circumstances, you may have the right to access and correct your
                personal information.
              </span>
            </li>
            <li>
              <span
                style={{ backgroundColor: "transparent", color: "#314146" }}
              >
                <strong>Right to Request Deletion.&nbsp;</strong>Under certain
                circumstances, you may have the right to request that Playsee
                delete personal information collected about you. This right is
                not absolute, and we may refuse such a request if there are
                compelling legitimate grounds for keeping your personal
                information, or as required by law.&nbsp; If your account is
                deleted, you will not be able to recover it. Personal
                information that others have shared about you is separate from
                your account. Additionally, if you have uploaded User Content,
                we store it in our system and even if you delete your account or
                content, we may still access and use User Content for certain
                purposes as permitted under applicable law, primarily for
                promotional purposes, as outlined further in the User Terms of
                Service.
              </span>
            </li>
            <li>
              <span
                style={{ backgroundColor: "transparent", color: "#314146" }}
              >
                <strong>Right to Restrict.&nbsp;</strong>In some jurisdictions,
                applicable law may give you the right to restrict or object to
                us processing or transferring your personal information under
                certain circumstances. We may continue to process your personal
                information if it is necessary for the defense of legal claims,
                or for any other exceptions permitted by applicable law.
              </span>
            </li>
            <li>
              <span
                style={{ backgroundColor: "transparent", color: "#314146" }}
              >
                <strong>Right to Data Portability.&nbsp;</strong>Under certain
                circumstances, you may have the right to receive the personal
                information concerning you, which you have provided to us, in a
                structured, commonly used and machine-readable format and to
                transmit that data to another entity without hindrance from us.
              </span>
            </li>
            <li>
              <span
                style={{ backgroundColor: "transparent", color: "#314146" }}
              >
                <strong>Right to Object.&nbsp;</strong>You may have the right to
                object to processing of your personal information for direct
                marketing purposes or if we are processing your personal
                information on the basis of our legitimate interest..
              </span>
            </li>
            <li>
              <span
                style={{ backgroundColor: "transparent", color: "#314146" }}
              >
                <strong>Right to Withdraw Consent.&nbsp;</strong>In certain
                jurisdictions, to the extent the processing of your personal
                information is based on your consent, you may withdraw your
                consent at any time. Such a withdrawal will not affect the
                lawfulness of the processing prior to the consent withdrawal.
              </span>
            </li>
          </ul>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              <strong>
                Right to Lodge a Complaint with the Supervisory Authority
              </strong>
              .
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              &nbsp;
            </span>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              You may have the right to lodge a complaint with your local data
              protection authority about our processing of your Personal
              Information. Contact details for data protection authorities in
              the European Economic Area are available&nbsp;
            </span>
            <a href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                <u>here</u>
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              , and contact details for the United Kingdom’s ICO are
              available&nbsp;
            </span>
            <a href="https://ico.org.uk/make-a-complaint">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                <u>here</u>
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              .
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              11. Marketing Communication
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              You can unsubscribe to advertising emails at any time by clicking
              the unsubscribe link within the email, or by contacting us
              at&nbsp;
            </span>
            <a href="mailto:privacy@playsee.co">
              <span
                style={{
                  backgroundColor: "transparent",
                  color: "hsl(0,0%,30%)"
                }}
              >
                privacy@playsee.co
              </span>
            </a>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              . Please note that, even if you unsubscribe from advertising
              emails, we will still send you any necessary, non-promotional
              emails about your purchases, account, or updates to our Privacy
              Notice and Terms of Service.
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              12. Additional Information for California Residents
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              If you are a California resident, this section applies to you in
              addition to the rest of this Privacy Notice.&nbsp;&nbsp;
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              <strong>California Shine the Light Disclosure</strong>
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              Under California’s “Shine the Light” law, California residents who
              provide personal information to the Service may request certain
              information regarding our disclosure of personal information to
              third parties for their direct marketing purposes. To make such a
              request, please or contact us at&nbsp;
            </span>
            <a href="mailto:privacy@playsee.co">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                privacy@playsee.co
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              .
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              <strong>
                Cal. Bus. &amp; Prof. Code § 22581 Disclosure.&nbsp;
              </strong>
              &nbsp;If you are a California resident under the age of 18, and a
              registered user of the Service where this Privacy Notice is
              posted, California Business and Professions Code Section 22581
              permits you to request and obtain removal of content or
              information you have publicly posted. To make such a request,
              please contact us at&nbsp;
            </span>
            <a href="mailto:privacy@playsee.co">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                privacy@playsee.co
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              {" "}
              with a detailed description of the specific content or
              information. Please be aware that such a request does not ensure
              complete or comprehensive removal of the content or information
              you have posted and that there may be circumstances in which the
              law does not require or allow removal even if requested.
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              <strong>Do Not Track.&nbsp; </strong>Do Not Track (DNT) is a
              feature offered by some browsers which, when enabled, sends a
              signal to web Services to request that your browsing is not
              tracked. Since uniform standards for “DNT” signals have not been
              adopted, Playsee does not currently process or respond to DNT
              requests.
            </span>
          </p>
          <h3>13. Children</h3>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              Playsee will never knowingly collect any personal information
              about children under the age of 13. If we obtain actual knowledge
              that we have collected personal information about a child under
              the age of 13, that information will be immediately deleted from
              our database.&nbsp;
            </span>
          </p>
          <h3>14. Privacy Notice Updates</h3>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              We may make changes to this Privacy Notice from time to time.
              Please review the “Last Updated” legend at the top of this page to
              determine when this Privacy Notice was last updated. Any changes
              to this Privacy Notice will become effective on the “Last Updated”
              date indicated above, unless noted otherwise. If we make material
              changes to the Privacy Notice, we will notify users as required by
              law.
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>15. Contact Us</span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              You can contact Playsee Pte. Ltd via email at
            </span>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              &nbsp;
            </span>
            <a href="mailto:privacy@playsee.co">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                privacy@playsee.co
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              .
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              &nbsp;
            </span>
          </p>
          <p>&nbsp;</p>
          <h2>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              <strong>Playsee Cookie Policy</strong>
            </span>
          </h2>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              This Cookie Policy (“Policy”) describes the different types of
              cookies and similar tracking technologies that may be applied on
              “Services”, which includes the Playsee mobile application and any
              related web Services, software applications, and technologies
              where this Privacy Notice is posted. This Policy also explains how
              we use cookies and for what purpose we use cookies, and learn
              about how to control cookie settings at your device level.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              For more information on our general information privacy practice
              on the personal information you pass to us, please see
              our&nbsp;Privacy Notice.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              We may update this Cookie Policy from time to time to reflect, for
              example, changes to the cookies we use or for other operational,
              legal or regulatory reasons. If we do, last modified date above
              will be updated.&nbsp; You should revisit this page if you wish to
              keep yourself informed.&nbsp; If you have any questions about our
              use of cookies or other technologies, please email us at&nbsp;
            </span>
            <a href="mailto:privacy@playsee.co">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                privacy@playsee.co
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#4c8eb4" }}>
              .
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              1. What are Cookies and Similar Tracking Technologies
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              A cookie is a small text file that a website asks your browser to
              store on your device in order to remember information about you,
              such as your language preference. Those cookies are set by us and
              are called first-party cookies. We also use third-party cookies –
              which are cookies set by an organization other than Playsee – for
              our advertising and marketing efforts.&nbsp;
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Cookies do not contain confidential information such as your email
              address, phone number or payment information. If you do choose to
              disable cookies, you may find that certain sections of our
              Services do not work properly.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Cookies are not the only way to recognize or track users of our
              Services. We may use other similar technologies from time to time,
              like web beacons (sometimes called "tracking pixels" or "clear
              gifs") to track users of our Services. These are tiny graphics
              files that contain a unique identifier that enable us to recognize
              when someone has interacted with our Services, such as opening an
              e-mail that we have sent them. The use of web beacons (or similar
              technologies) allow us, for example, to monitor the traffic
              patterns of users within our Services, to deliver or communicate
              with cookies, to understand whether you have come to our Services
              from an online advertisement displayed on a third-party website,
              to improve our online services, and to measure the success of
              e-mail marketing campaigns. In many instances, these technologies
              are reliant on cookies to function properly, and so declining
              cookies will impair their functioning.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              We and our service providers and third parties we collaborate
              with, including ad networks, may use cross-device/cross-context
              tracking. For example, you might use multiple browsers on a single
              device, or use various devices (such as desktops, smartphones, and
              tablets), which can result in your having multiple accounts or
              profiles across these various contexts and devices.
              Cross-device/cross-context technology may be used to connect these
              various accounts or profiles and the corresponding data from the
              different contexts and devices.
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              2. Why We Use Cookies
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              When you access our Services, we or companies we work with may
              place cookies on your computer or other device. These technologies
              help us better understand user behavior, and inform us about which
              parts of our websites people have visited.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              We use first party and third party cookies to recognize you as a
              Playsee user, customize our content, and advertising, to measure
              promotional effectiveness, and to collect information about your
              computer or other access device to mitigate risk, help prevent
              fraud, and promote trust and safety.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              We may place cookies from third-party service providers who may
              use information about your visits to other websites to target
              advertisements for products and services available from Playsee.
              We do not control the types of information collected and stored by
              these third-party cookies. You should check the third-party's
              website for more information on how they use cookies.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              The following are some examples of information that we collect and
              how we may use it:
            </span>
          </p>
          <ul>
            <li>
              <span
                style={{
                  backgroundColor: "transparent",
                  color: "hsl(0,0%,30%)"
                }}
              >
                We may collect and store details of how you use our
                Services.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  backgroundColor: "transparent",
                  color: "hsl(0,0%,30%)"
                }}
              >
                We may collect information such as your location, community,
                language, inferred zip code or area code, unique device
                identifier, referrer URL, location, and time zone so that we can
                better understand customer behavior and improve our services.
              </span>
            </li>
          </ul>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              3. Types of Cookies We Use
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              We may use the following types of cookies on our Services:&nbsp;
            </span>
          </p>
          <figure className="table">
            <table>
              <thead>
                <tr>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>Type of Cookie</strong>
                      </span>
                    </p>
                  </th>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>Purpose of Cookie</strong>
                      </span>
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Strictly Necessary or Essential Cookies
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Essential cookies are also referred to as “strictly
                      necessary” since without them we cannot provide the
                      functionality required to use the Services. It is used to
                      identify you, prevent security risks, and to allow you to
                      perform account-related functions.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Statistics/Analytics
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Statistics/Analytics Cookies track information about
                      visits to the Services so that we can make improvements
                      and report on performance. These Cookies collect
                      information about how visitors use the Services, which
                      site or page the user came from, the number of each user’s
                      visits and how long a user stays on the Services. We may
                      also use Statistics/Analytics Cookies to test new features
                      to see how users react to them.&nbsp;
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Functional Cookies
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      During users’ interaction with the Services, Functional
                      Cookies are used to remember information users have
                      entered. They also store user preferences when
                      personalizing the Services to optimize the use of the
                      Services. These preferences are remembered through the use
                      of the persistent Cookies, and the next time a user
                      engages with the Services they will not have to set them
                      again.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Social Media Cookies
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      These cookies are set by a range of social media services
                      that we have added to the site to enable you to share our
                      content with your friends and networks. They are capable
                      of tracking your browser across other sites and building
                      up a profile of your interests. This may impact the
                      content and messages you see on other websites you
                      visit.&nbsp; If you do not allow these cookies you may not
                      be able to use or see these sharing tools.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Targeting Cookies
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      We and third-party advertising partners may use cookies to
                      collect data from visitors to our sites for analytics and
                      targeted advertising purposes. These cookies may be used
                      to build a profile of your interests and show you relevant
                      ads on other sites. They are based on uniquely identifying
                      your browser and internet device. If you do not allow
                      these cookies, you will still continue to see generic
                      advertising on our site and elsewhere online.
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              In Appendix A, you can view a chart with more information about
              the specific cookies on our Services.
            </span>
          </p>
          <h3>
            <span style={{ color: "hsl(0,0%,30%)" }}>
              4. How to Manage Cookies and Targeted Mobile Advertising
            </span>
          </h3>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              You have the right to decide whether to accept cookies. If you do
              not want us to deploy cookies in your browser, you may exercise
              your preference by clicking “Your Privacy Rights” in the footer,
              by clicking here or by modifying your web browser setting to
              refuse some or all cookies or to notify you and ask for your
              permission when a website tries to set a cookie. If you want to
              learn how to modify your browser settings, please use the “Help,”
              “Tools” or “Edit” menu in your browser or review the instructions
              provided by the following browsers:
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              &nbsp;
            </span>
            <a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                Microsoft Edge
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#4c8eb4" }}>
              ,&nbsp;
            </span>
            <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                Google Chrome
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#4c8eb4" }}>
              ,&nbsp;
            </span>
            <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                Mozilla Firefox
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#4c8eb4" }}>
              ,&nbsp;
            </span>
            <a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                Safari Desktop
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#4c8eb4" }}>
              ,&nbsp;
            </span>
            <a href="https://support.apple.com/en-us/HT201265">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                Safari Mobile;
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#0070c0" }}>
              {" "}
            </span>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              and&nbsp;
            </span>
            <a href="https://support.google.com/nexus/answer/54068?visit_id=637284515772371005-3491757126&hl=en&rd=">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                Android
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              {" "}
            </span>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              browser. If you block or delete technical and/or function cookies
              used by our Services, the Services may become impossible to
              browse, certain services or functions of the Services may become
              unavailable or other malfunctions may occur. In this case, you may
              have to modify or manually enter some information or preferences
              every time you visit the Services.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              <i>
                <strong>Analytics</strong>
              </i>
              . We may use third-party service providers to monitor and analyze
              the use of our Services, such as Google Analytics. Google
              Analytics is a web analytics service offered by Google LLC
              (“Google”) that tracks and reports website traffic. For more
              information on the privacy practices of Google, please visit the
              Google Privacy &amp; Terms web
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              {" "}
            </span>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              page:&nbsp;
            </span>
            <a href="https://policies.google.com/privacy?hl=en">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                <u>https://policies.google.com/privacy?hl=en</u>
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#4c8eb4" }}>
              .
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              {" "}
            </span>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Google Analytics Opt-out Browser Add-on provides visitors with the
              ability to prevent their data from being collected and used by
              Google Analytics, available at:
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              &nbsp;
            </span>
            <a href="https://tools.google.com/dlpage/gaoptout">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                <u>https://tools.google.com/dlpage/gaoptout</u>
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              .
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              <i>
                <strong>
                  <u>Managing Interest-Based Advertising&nbsp;</u>
                </strong>
              </i>
            </span>
          </p>
          <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              <i>
                <strong>Website Browser</strong>
              </i>
              . Many advertising companies that collect information for
              interest-based advertising are members of the Digital Advertising
              Alliance (DAA) or the Network Advertising Initiative (NAI), both
              of which maintain web Services where people can opt out of
              interest-based advertising from their members. To opt out of
              website interest-based advertising provided by each organization’s
              respective participating companies, visit the DAA’s opt-out portal
              available at
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              &nbsp;
            </span>
            <a href="http://optout.aboutads.info/">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                <u>http://optout.aboutads.info/</u>
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              , or visit the NAI’s opt-out portal available at&nbsp;
            </span>
            <a href="http://optout.networkadvertising.org/?c=1">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                <u>http://optout.networkadvertising.org/?c=1</u>
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              .
            </span>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              {" "}
              Residents of the European Union may opt-out of online behavioral
              advertising served by the European Interactive Digital Advertising
              Alliance’s participating member organizations by visiting
            </span>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              &nbsp;
            </span>
            <a href="https://www.youronlinechoices.eu/">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                <u>https://www.youronlinechoices.eu/</u>
              </span>
            </a>
            <span style={{ backgroundColor: "transparent", color: "#000000" }}>
              .
            </span>
          </p>
          <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              <i>
                <strong>Mobile Application</strong>
              </i>
              <strong>.&nbsp;</strong>When you use our mobile application, we or
              our third-party marketing partners may use one or more of several
              different identifiers for your mobile device, including Apple
              Identifier For Advertising (IDFA) or Android Advertising ID
              (AAID), to target and deliver ads to you in our app or other apps.
              This means that your device identifier may be accessed by
              third-party ad networks and used to (a) help manage the number and
              types of ads you see; (b) track the source of installs related to
              ads seen in other apps; and (c) identify your interests and
              behavior and target advertising to you based on those interests
              and behavior.
            </span>
          </p>
          <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              Apple requires app developers to ask for permission before they
              can track your activity across apps or web Services they do not
              own in order to target advertising to you, measure your actions
              due to advertising, or to share your information with data
              brokers. If you previously gave our mobile app permission to
              track, you can tell our app to stop tracking your activity. On iOS
              or iPadOS, go to Settings, tap on our app, then tap to turn off
              Allow Tracking. You can also reset your IDFA from your mobile
              device’s settings page, which will prevent continued use of
              existing behavioral data tied to your previous IDFA.&nbsp;
            </span>
          </p>
          <p style={{ marginLeft: "36pt", textAlign: "justify" }}>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              If you have an Android device, and are running Android 12 or
              above, you can delete your AAID permanently by opening Settings,
              navigating to Privacy &gt; Ads, tapping “Delete advertising ID,”
              then tapping it again on the next page to confirm. This will
              prevent any app on your phone from accessing it in the future.
              Please note that if you do so, you will still see advertisements,
              but they will not be tailored to your inferred interests.
            </span>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              You may also access industry-provided opt-out tools, although they
              are not tailored to our mobile applications.&nbsp; For instance,
              to opt-out of data collection by the Digital Advertising
              Alliance’s participating member companies for interest-based
              advertising across mobile applications, download the DAA’s App
              Choices mobile application opt-out offering at
            </span>
            <span style={{ backgroundColor: "transparent", color: "#4c8eb4" }}>
              &nbsp;
            </span>
            <a href="https://youradchoices.com/appchoices.">
              <span
                style={{ backgroundColor: "transparent", color: "#4c8eb4" }}
              >
                <u>https://youradchoices.com/appchoices</u>
              </span>
              <span
                style={{ backgroundColor: "transparent", color: "#000000" }}
              >
                .
              </span>
            </a>
          </p>
          <p>
            <span
              style={{ backgroundColor: "transparent", color: "hsl(0,0%,30%)" }}
            >
              <strong>APPENDIX A -&nbsp; COOKIE CHART</strong>
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "transparent", color: "#314146" }}>
              We use the following cookies in connection with our Services:
            </span>
          </p>
          <figure className="table">
            <table>
              <thead>
                <tr>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>Name</strong>
                      </span>
                    </p>
                  </th>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>File(s)</strong>
                      </span>
                    </p>
                  </th>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>Explanation</strong>
                      </span>
                    </p>
                  </th>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>Lifespan</strong>
                      </span>
                    </p>
                  </th>
                  <th>
                    <p style={{ textAlign: "center" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "hsl(0,0%,30%)"
                        }}
                      >
                        <strong>Category</strong>
                      </span>
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Google Analytics
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      _utma_utmb_utmc_utmz
                    </span>
                    <br />
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      ga.js
                    </span>
                    <br />
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      __utm*
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Our Services use Google Analytics, a web analytics service
                      provided by Google, Inc. (“Google”). Google Analytics uses
                      cookies, which are text files placed on your computer, to
                      help the website analyze how users use the site. The
                      information generated by the cookie about your use of the
                      website will be transferred to and stored by Google on
                      servers in Singapore. Before the transfer this information
                      will be anonymized by removing the last octet of your IP
                      address. Google will process this information on our
                      behalf for the purpose of evaluating your use of the
                      website, compiling reports on website activity and
                      providing other services relating to website activity and
                      internet usage. Google will not link your IP address with
                      any other data held by Google. You may refuse the use of
                      cookies by selecting the appropriate settings on your
                      browser, however please note that if you do this you may
                      not be able to use the full functionality of this website.
                      Information collected by us through the use of this cookie
                      includes: number of visitors, referring URLs, and the
                      pages visited. For further information, please see:
                    </span>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "#000000"
                      }}
                    />
                    <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">
                      <span
                        style={{
                          backgroundColor: "transparent",
                          color: "#4c8eb4"
                        }}
                      >
                        Google Analytics Cookie Usage on WebServices | Analytics
                        for Web (analytics.js) | Google Developers
                      </span>
                    </a>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "#000000"
                      }}
                    >
                      Expires 2 years from set date
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "#000000"
                      }}
                    >
                      Analytics
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      beatSessionId
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Registers which server-cluster is serving the visitor.
                      This is used in context with load balancing, in order to
                      optimize user experience.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Persistent
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Essential
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      beatSessionTs
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Registers which server-cluster is serving the visitor.
                      This is used in context with load balancing, in order to
                      optimize user experience.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Persistent
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Essential
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      hs
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Ensures visitor browsing-security by preventing cross-site
                      request forgery. This cookie is essential for the security
                      of the website and visitor.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Session
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Essential
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      TS#
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Ensures security.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Session
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Essential
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      XSRF-TOKEN
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Ensures visitor browsing-security by preventing cross-site
                      request forgery. This cookie is essential for the security
                      of the website and visitor.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Session
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Essential
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      _ga
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Registers a unique ID that is used to generate statistical
                      data on how the visitor uses the website.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      2 years
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Analytics
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      _gat
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Used by Google Analytics to throttle request rate
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      1 day
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Analytics
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      _gid
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Registers a unique ID that is used to generate statistical
                      data on how the visitor uses the website.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      1 day
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Analytics
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      fedops.logger.sessionId
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Registers statistical data on visitors' behavior on the
                      website. Used for internal analytics by the website
                      operator.
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Persistent
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Functional
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      SSR-caching
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      HTML
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Indicates how a site was rendered
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Session
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "transparent",
                        color: "hsl(0,0%,30%)"
                      }}
                    >
                      Essential
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
          <p>&nbsp;</p>
        </div>
        <div className="mx-auto pt-[32px] text-2xl font-semibold">
          Updated on2023/11/20
        </div>
      </main>
    </div>
  </div>
    </>
  );
}

export default App;
